<template>
  <div class="card-container unselect" @click="clickDetail(card)">
    <div class="card-content">
      <div class="flex-align">
        <div class="card-img" :style="imgStyle"></div>
        <div style="flex:1;padding-left:12px">
          <div class="subtitle6 main margin-top-12">{{ card.name }}</div>
          <div class="body5 sub3 ellipsis-2">{{ card.simple_desc }}</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="flex-between">
        <!-- 가격 정보 -->
        <div>
          <div v-if="discountedPrice(card)>0" class="margin-top-8">
            <div class="body6 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>
            <div class="flex-align">
              <div class="margin-right-4 primary body4-bold" v-if="card.price.discount_type===0">{{ discountText(card) }}</div>
              <div class="body4-bold">
                <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
                <span class="body4 sub2">원</span>
                <span class="body4 sub3" v-if="card.price.is_subscription">/월</span>
                <span class="body4 sub2 margin-left-4" v-if="card.category2!==42">~</span>
              </div>
            </div>
          </div>
        </div>
        <div :class="`card-status-${card.status}`" v-if="card.status!==undefined">
          <svg v-if="card.status===2" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 6L9 17L4 12" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>{{ statusText(card.status) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardProductServicePc",
    props: {
      card: {
        type: Object
      }
    },
    created() {
      this.setTagLength();
    },
    data() {
      return {
        tagLen: 0
      }
    },
    watch: {
      'card.tags'() {
        this.setTagLength();
      }
    },
    computed: {
      imgStyle() {
        return `backgroundImage: url(${this.card.img})`;
      }
    },
    methods: {
      setTagLength() {
        this.tagLen = 0;
        if(this.card.tags.length>0) {
          let len = 0;
          let limit = 20;
          this.card.tags.forEach(tag => {
            len += tag.length;
            if(len < limit) {
              this.tagLen++;
              limit = this.tagLen === 1 ? 19 : 16;
            }
          });
        }
        if(this.tagLen===0) {
          this.tagLen = 1;
        }
      },
      statusText(status) {
        const arr = {
          0: '추가',
          1: '신청 중',
          2: '이용 중'
        };

        return arr[status];
      },
      discountText(product) {
        let text = '';
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            text = product.price.discount_rate + '%'
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            text = product.price.discount_price + '원';
          }
        }
        return text;
      },
      // 할인 여부
      isDiscount(product) {
        if (product.price.is_discount && product.price.discount_start !== null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < product.price.discount_start || currentDate > product.price.discount_end);
        } else if (product.price.discount_start !== null && product.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= product.price.discount_start;
        } else if (product.price.discount_start === null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= product.price.discount_end;
        } else {
          return false;
        }
      },
      // 상품 가격
      discountedPrice(product) {
        let total = 0;
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            total = product.price.price * (1 - (product.price.discount_rate / 100))
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            total = product.price.price - product.price.discount_price;
          }
        } else total = product.price.price;
        return total;
      },
      imgField(img) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top'
        };
        return style;
      },
      clickDetail(card) {
        this.routeGa('',`추가서비스 카드(${card.name})`, card.name);
        if (this.$route.path.search('detail') > -1) {
          this.$router.push(`/market/detail?id=${card.id}`)
        }
        else if(this.$route.path.search('app_market') > -1) {
          this.$router.push(`/app_market/detail?id=${card.id}`)
        }
        else {
          this.$router.push(`/market/detail?id=${card.id}`)
        }
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .card-container
    background-color white
    border-radius 8px
    border 1px solid $gray2

  .card-img
    width 60px
    height 60px
    border-radius 10px
    background-color $gray3
    display flex
    align-items center
    justify-content center
    background-repeat no-repeat
    background-size cover
    background-position center

  .card-content
    padding 20px

  .tag
    border 1px solid $gray1
    border-radius 50px
    padding 2px 8px
    margin-right 4px

  .card-status-0
  .card-status-1
  .card-status-2
    border-radius 50px
    padding 0 20px
    line-height 32px
    height 32px
    margin-top 12px

  .card-status-0
    background-color $primary
    color white
  .card-status-1
    background-color $primary-light2
    color $primary
  .card-status-2
    background-color $main
    color white
    svg
      margin-right 4px
      width 12px
      height 12px

  .divider
    width 100%
    height 1px
    background-color $gray2
    margin 12px 0

</style>
